import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const notFound = "NOT FOUND";
const notFoundText =
  "You just hit a route that doesn&#39;t exist... the sadness.";

const NotFoundPage = ({ pageContext }) => (
  <Layout pageContext={pageContext}>
    <SEO title="404: Not found" />
    <h1>{notFound}</h1>
    <p>{notFoundText}</p>
  </Layout>
);

export default NotFoundPage;
